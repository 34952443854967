<template>
  <div>
    <v-container fluid class="pa-0 categories">
      <v-row justify="center" class="text-center">
        <v-col cols="10" class="">
          <v-row justify="center" class="my-8">
            <v-col cols="12" md="6">
              <h1 class="h1_title">{{ getLang['HomePage']['h1_title'] }}</h1>
            </v-col>
          </v-row>
          <v-row justify="center" class="my-5">
            <v-col cols="12" md="4">
              <v-card href="/category/all/road_bikes" color="transparent" flat class="rounded-xl">
                <v-img width="100%" height="100%" aspect-ratio="1" src="../../assets/HomePage/Categories/1.png"
                  lazy-src="../../assets/HomePage/Categories/lazy/1.png" class="cards_img"
                  alt="Motocicletă de drum pe autostradă cu motociclist">
                  <div class="fill-height d-flex flex-column justify-end text-left">
                    <v-card-title class="title_card fontB font_wrap">
                      {{ getLang.HomePage.ThreeSubcategories.Road_bikes.title }}
                    </v-card-title>
                    <v-card-subtitle class="subtitle_card fontR">
                      <span v-html="getLang.HomePage.ThreeSubcategories.Road_bikes
                          .subtitle
                        "></span>
                    </v-card-subtitle>
                  </div>
                </v-img>
              </v-card>
            </v-col>
            <v-col cols="12" md="4">
              <v-card href="/category/all/Motocross" color="transparent" flat class="rounded-xl">
                <v-img width="100%" height="100%" aspect-ratio="1" src="../../assets/HomePage/Categories/2.png"
                  lazy-src="../../assets/HomePage/Categories/lazy/2.png" class="cards_img"
                  alt="Motocross bike în aer, cu biker la ghidon">
                  <div class="fill-height d-flex flex-column justify-end text-left">
                    <v-card-title class="title_card fontB font_wrap">
                      {{ getLang.HomePage.ThreeSubcategories.Enduro_MX.title }}
                    </v-card-title>
                    <v-card-subtitle class="subtitle_card fontR">
                      <span v-html="getLang.HomePage.ThreeSubcategories.Enduro_MX.subtitle
                        "></span>
                    </v-card-subtitle>
                  </div>
                </v-img>
              </v-card>
            </v-col>
            <v-col cols="12" md="4">
              <v-card href="/category/all/Bud_racing" color="transparent" flat class="rounded-xl">
                <v-img width="100%" height="100%" aspect-ratio="1" src="../../assets/HomePage/Categories/3.png"
                  lazy-src="../../assets/HomePage/Categories/lazy/3.png" class="cards_img"
                  alt="Rider enduro în echipament alb pe motocicletă">
                  <div class="fill-height d-flex flex-column justify-end text-left">
                    <v-card-title class="title_card fontB font_wrap">
                      {{ getLang.HomePage.ThreeSubcategories.Equipment.title }}
                    </v-card-title>
                    <v-card-subtitle class="subtitle_card fontR">
                      <span v-html="getLang.HomePage.ThreeSubcategories.Equipment.subtitle
                        "></span>
                    </v-card-subtitle>
                  </div>
                </v-img>
              </v-card>
            </v-col>
          </v-row>
          <v-row justify="center" class="mt-10 mb-4">
            <v-col cols="12" md="5" align-self="center">
              <v-card flat color="transparent" class="card_left text-left">
                <v-card-title class="left_card_title pa-0 fontB">
                  {{ getLang.HomePage.Categories.Rieju_moto.title }}
                </v-card-title>
                <v-card-subtitle class="left_card_subtitle fontB pa-0 mt-1">
                  {{ getLang.HomePage.Categories.Rieju_moto.subtitle }}
                </v-card-subtitle>
                <span class="left_card_text px-0 d-flex">
                  <v-img max-width="30px" max-height="18px" class="mt-1" src="../../assets/HomePage/Categories/5.png">
                  </v-img>
                  <span class="ml-2 fontB">
                    {{ getLang.HomePage.Categories.Rieju_moto.made_in }}
                  </span>
                </span>
                <v-card-text class="left_card_text2 px-0 fontB">
                  <span v-html="getLang.HomePage.Categories.Rieju_moto.text"></span>
                </v-card-text>
                <v-btn href="/category/Rieju_bikes" class="mt-3 btn_carousel fontR px-12" color="btnYellow"
                  elevation="0">
                  {{ getLang.HomePage.Categories.Rieju_moto.button }}
                </v-btn>
              </v-card>
            </v-col>
            <v-col cols="12" md="7" align-self="center">
              <v-img max-width="100%" max-height="100%" src="../../assets/HomePage/Categories/Rieju motorbikes.webp"
                lazy-src="../../assets/HomePage/Categories/Rieju motorbikes_lazy.webp"
                alt="Furcă enduro motocicletă Rieju, Rider enduro în pădure pe motocicletă enduro">
              </v-img>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CategoriesBlock",
  computed: {
    ...mapGetters(["getLang"]),
  },
};
</script>

<style scoped>
.h1_title {
  font-weight: 500;
  font-size: 1.6rem;
}
.btn_carousel {
  text-shadow: 0 0 3px #574A0A;
  color: #fff;
  border-radius: 0px;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.02em;
}

.left_card_text2 {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -0.05em;
  color: #040c1b;
}

.left_card_text {
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #333333;
}

.left_card_title {
  font-weight: 600;
  font-size: 52px;
  line-height: 65px;
  letter-spacing: -0.02em;
  color: #040c1b;
}

.left_card_subtitle {
  font-weight: 600;
  font-size: 32px;
  line-height: 45px;
  letter-spacing: -0.02em;
  color: #dcbb10 !important;
}

.title_card {
  font-weight: 600;
  font-size: 35px;
  line-height: 40px;
  letter-spacing: -0.02em;
  color: #ebebeb;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.6);
}

.subtitle_card {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.05em;
  color: #dcbb10;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}

.cards_img {
  padding: 13px;
  transition: 0.3s;
}

.cards_img:hover {
  filter: contrast(1.25);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
}

/* .categories {
  background: url("../../assets/background.png");
} */
</style>