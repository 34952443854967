<template>
  <v-container fluid class="pa-0 categories mb-15 pt-15" id="contacte">
    <v-row justify="center" class="text-start">
      <v-col cols="10" class="">
        <v-row justify="center">
          <v-col cols="12" md="6" align-self="center">
            <v-card-title class="title_contacts fontB">
              {{ getLang.AboutPage.contact }}
            </v-card-title>
            <v-list three-line class="list_contacts">
              <v-list-item class="my-6">
                <v-list-item-avatar class="my-auto">
                  <svg
                    width="46"
                    viewBox="0 0 46 47"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="23" cy="23.5" r="23" fill="#040C1B" />
                    <path
                      d="M22.9998 35.46C22.9998 35.46 31.8931 26.741 31.8931 20.1266C31.8931 15.0456 27.9114 10.9266 22.9998 10.9266C18.0881 10.9266 14.1064 15.0456 14.1064 20.1266C14.1064 26.741 22.9998 35.46 22.9998 35.46ZM22.9998 24.7266C20.544 24.7266 18.5531 22.6671 18.5531 20.1266C18.5531 17.5861 20.544 15.5266 22.9998 15.5266C25.4556 15.5266 27.4464 17.5861 27.4464 20.1266C27.4464 22.6671 25.4556 24.7266 22.9998 24.7266Z"
                      fill="#E0E1E2"
                    />
                  </svg>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="fontB">
                    {{ getLang.AboutPage.address }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <span>{{ getLang.AboutPage.address_line1 }}</span><br />
                    <span class="mt-2">{{ getLang.AboutPage.address_line2 }}</span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="my-6" three-line>
                <v-list-item-avatar class="my-auto">
                  <svg
                    width="46"
                    viewBox="0 0 46 47"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="23" cy="23.5" r="23" fill="#040C1B" />
                    <path
                      d="M20.287 27.717C22.8513 30.2813 25.862 32.1272 29.0689 33.2547C30.1213 33.6247 31.2765 33.3006 32.0654 32.5118L33.3989 31.1783C34.0634 30.5137 33.9952 29.417 33.2533 28.84L30.2789 26.5266C29.8999 26.2318 29.4064 26.1276 28.9406 26.2441L26.1172 26.9499C25.5848 27.083 25.0215 26.927 24.6334 26.5389L21.465 23.3705C21.0769 22.9824 20.9209 22.4192 21.054 21.8867L21.7598 19.0633C21.8763 18.5975 21.7721 18.104 21.4773 17.725L19.1639 14.7506C18.5869 14.0087 17.4902 13.9405 16.8256 14.605L15.4921 15.9386C14.7033 16.7274 14.3792 17.8826 14.7492 18.935C15.8767 22.1419 17.7226 25.1526 20.287 27.717Z"
                      fill="#E0E1E2"
                    />
                  </svg>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="fontB pb-2">
                    {{ getLang.AboutPage.tel }}
                  </v-list-item-title>
                  <v-list-item-subtitle style="-webkit-line-clamp: 3">
                    <span>+37369114788 </span><br />
                    <span>+37369144616</span><br />
                    <span>+37379101030</span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="my-6">
                <v-list-item-avatar class="my-auto">
                  <svg
                    width="46"
                    viewBox="0 0 46 47"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="23" cy="23.5" r="23" fill="#040C1B" />
                    <path
                      d="M15.2996 20.0554C15.5017 19.1648 16.2982 18.5 17.25 18.5H29.25C30.2018 18.5 30.9983 19.1648 31.2004 20.0554L23.25 24.914L15.2996 20.0554ZM15.25 21.1971V28.3006L21.0532 24.7435L15.25 21.1971ZM22.0114 25.3291L15.4419 29.3559C15.7626 30.0323 16.4517 30.5 17.25 30.5H29.25C30.0483 30.5 30.7374 30.0323 31.0581 29.3559L24.4886 25.3291L23.25 26.086L22.0114 25.3291ZM25.4468 24.7435L31.25 28.3006V21.1971L25.4468 24.7435Z"
                      fill="#E0E1E2"
                    />
                  </svg>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="fontB">
                    {{ getLang.AboutPage.mail }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <span>contact@proenduro.md</span><br />
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col
            cols="12"
            md="5"
            align-self="center"
            style="position: relative"
          >
            <v-img
              class="img_absolute"
              max-width="100%"
              aspect-ratio="1"
              max-height="100%"
              src="../../assets/HomePage/ContactUs/Husqvarna enduro ride.webp"
            >
            </v-img>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "contactAbout",
  data: () => ({}),
  computed: {
    ...mapGetters(["getLang"]),
  },
};
</script>

<style scoped>
.list_contacts {
  background-color: transparent;
  border-right: 1px solid rgba(4, 12, 27, 0.13);
}
.title_contacts {
  font-weight: 600;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: -0.05em;
  color: #040c1b;
}
</style>