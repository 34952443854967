<template>
  <div class="CategoryPage">
    <ParallaxCategory />
    <SubCategories />
    <Catalog :products="getProducts" />
    <BestProduct />
    <Parallax />
    <ContactUs />
    <FooterPage />
  </div>
</template>

<script>
import ParallaxCategory from "../components/Category/ParallaxCategory.vue";
import SubCategories from "../components/Category/SubCategories.vue";
import Catalog from "../components/Category/Catalog.vue";
import BestProduct from "../components/Category/BestProduct.vue";
import Parallax from "../components/Home/Parallax.vue";
import ContactUs from "../components/Home/ContactUs.vue";
import FooterPage from "../components/FooterPage.vue";
import { mapGetters } from "vuex";

export default {
  name: "HomeView",
  components: {
    ParallaxCategory,
    SubCategories,
    Catalog,
    BestProduct,
    Parallax,
    ContactUs,
    FooterPage,
  },
  computed: {
    ...mapGetters(["getProducts"]),
  },
  beforeCreate() {
    /* let data = {
      name: this.$route.params.name,
      subname: this.$route.params.subname,
    }; */
    let data = {
      changed_categories: [this.$route.params.name],
      changed_subcategories: this.$route.params.subname ? [this.$route.params.subname] : null
    };
    this.$store.dispatch("LoadProducts", data);
  },
};
</script>
<style scoped>
.CategoryPage {
  background-color: #F1F1F1;
}
</style>