<template>
  <div class="AboutPage">
    <ParallaxAbout />
    <AboutText />
    <ourProducts />
    <ourPartners />
    <contactAbout />
    <Parallax />
    <FooterPage />
  </div>
</template>
    
<script>
import ParallaxAbout from "../components/About/parallaxAbout.vue";
import AboutText from "../components/About/AboutText.vue";
import ourProducts from "../components/About/ourProducts.vue";
import ourPartners from "../components/About/ourPartners.vue";
import contactAbout from "../components/About/contactAbout.vue";
import Parallax from "../components/Home/Parallax.vue";
import FooterPage from "../components/FooterPage.vue";

export default {
  name: "AboutPage",

  components: {
    ParallaxAbout,
    AboutText,
    ourProducts,
    ourPartners,
    contactAbout,
    Parallax,
    FooterPage,
  },
};
</script>