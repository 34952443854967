<template>
  <div class="AboutPage pt-15">
    <v-row justify="center" class="mx-0">
      <v-col cols="11" sm="11" lg="10">
        <h2 class="text_title fontB my-15">
          {{ title }}
        </h2>
        <hr class="mb-10" style="border: 1px solid #cecece" />

        <v-tabs
          background-color="transparent"
          v-model="model"
          color="#040C1B"
          class="mb-15"
        >
          <v-tab
            v-for="(item, i) in items"
            :key="`#tab-${i}`"
            class="tab fontB"
            :href="`#tab-${i}`"
          >
            {{ item }}
          </v-tab>
        </v-tabs>
        <v-tabs-items class="tabs_items mb-15" v-model="model">
          <v-tab-item
            v-for="(text, i) in texts"
            :key="text + i"
            :value="`tab-${i}`"
          >
            <v-card flat color="transparent">
              <span class="px-0 text_terms d-block fontR" v-html="text"></span>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <FooterPage />
  </div>
</template>
    
<script>
import Terms from "../assets/language/Terms.json";
import FooterPage from "../components/FooterPage.vue";
import { mapGetters } from "vuex";

export default {
  name: "HomeView",
  components: {
    FooterPage,
  },
  data() {
    return {
      model: "",
    };
  },
  mounted() {
    switch (this.$route.params.id) {
      case "0":
        this.model = "tab-terms";
        break;
      case "1":
        this.model = "tab-politica";
        break;
      default:
        this.model = "tab-terms";
        break;
    }
  },
  computed: {
    ...mapGetters(["getLanguage"]),
    items() {
      return Terms[this.getLanguage].items;
    },
    texts() {
      return Terms[this.getLanguage].texts;
    },
    title() {
      return Terms[this.getLanguage].title;
    },
  },
};
</script>
<style scoped>
.text_terms {
  font-weight: 500;
  font-size: 16px;
  text-align: justify;
  letter-spacing: 0.03em;
  color: #040c1b;
}
.tab {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.03em;
  text-transform: none;
  color: #040c1b;
}
.tabs_items {
  background-color: transparent !important;
}
.text_title {
  font-weight: 600;
  font-size: 45px;
  line-height: 55px;
  letter-spacing: -0.05em;
  color: #040c1b;
}
.AboutPage {
  background-color: #f1f1f1;
}
</style>