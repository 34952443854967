<template>
  <div>
    <v-container fluid class="pa-0 bud_racing">
      <v-row justify="center" class="text-center">
        <v-col cols="10" class="">
          <v-row justify="center" class="mt-10 mb-4">
            <v-col cols="12" md="7" align-self="center">
              <v-card flat color="transparent" class="card_left text-left">
                <v-card-title class="left_card_title pa-0 fontB font_wrap">
                  {{ getLang.HomePage.Categories.Bud_racing.title }}
                </v-card-title>
                <v-card-subtitle
                  class="left_card_subtitle fontB pa-0 mt-1 font_wrap"
                >
                  {{ getLang.HomePage.Categories.Bud_racing.subtitle }}
                </v-card-subtitle>
                <span class="left_card_text px-0 d-flex">
                  <v-img
                    max-width="30px"
                    max-height="18px"
                    class="mt-1"
                    src="../../assets/HomePage/bud_racing/2.png"
                  >
                  </v-img>
                  <span class="ml-2 fontB">
                    {{ getLang.HomePage.Categories.Bud_racing.made_in }}
                  </span>
                </span>
                <v-card-text class="left_card_text2 px-0 fontB">
                  <span
                    v-html="getLang.HomePage.Categories.Bud_racing.text"
                  ></span>
                </v-card-text>
                <v-btn
                  href="/category/bud_racing"
                  class="mt-3 btn_carousel fontR px-12"
                  color="btnYellow"
                  elevation="0"
                >
                  {{ getLang.HomePage.Categories.Bud_racing.button }}
                </v-btn>
              </v-card>
            </v-col>
            <v-col cols="12" md="5" align-self="center">
              <v-img
                max-width="100%"
                max-height="100%"
                src="../../assets/HomePage/bud_racing/BUD parts for motorcycles.webp"
                lazy-src="../../assets/HomePage/bud_racing/BUD parts for motorcycles_lazy.webp"
                alt="Motocross bike verde, Piese de schimb de la compania BUD, Motociclist pe motocross bike verde"
              >
              </v-img>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
  
<script>
import { mapGetters } from "vuex";

export default {
  name: "BudRacing",
  computed: {
    ...mapGetters(["getLang"]),
  },
};
</script>
  
  <style scoped>
.btn_carousel {
  text-shadow: 0 0 3px #574A0A;
  color: #fff;
  border-radius: 0px;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.02em;
}
.left_card_text2 {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -0.05em;
  color: #040c1b;
}
.left_card_text {
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #333333;
}
.left_card_title {
  font-weight: 600;
  font-size: 52px;
  line-height: 65px;
  letter-spacing: -0.02em;
  color: #040c1b;
}
.left_card_subtitle {
  font-weight: 600;
  font-size: 32px;
  line-height: 45px;
  letter-spacing: -0.02em;
  color: #dcbb10 !important;
}
/* .bud_racing {
  background: url("../../assets/background.png");
} */
</style>