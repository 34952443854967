<template>
  <v-container fluid class="pa-0 parallaxHome">
    <v-parallax height="350" class="pa-0" dark src="../../assets/Category/1.png">
      <v-row justify="center" class="fill-height" style="flex-flow: nowrap">
        <v-col cols="12" sm="9" align-self="start" style="height: 100%; display: flex; flex-direction: column; justify-content: space-between;">
          <v-breadcrumbs class="breadcrumbs_text fontB justify-center" dark large :items="links"
            divider=">"></v-breadcrumbs>
          <h1 class="text-center h1_categorie_title">{{ CategoriesJSON.filter((categ) =>
            categ["Name code"]
              .toLowerCase()
              .includes(this.$route.params.name.toLowerCase() == 'all' && this.$route.params.subname ? this.$route.params.subname.toLowerCase() : this.$route.params.name.toLowerCase())
          )[0][`Name ${getLanguage}`] }}</h1>
          <div></div>
        </v-col>
      </v-row>
    </v-parallax>
    <v-row justify="center" class="text-center menu_sub pa-5 ma-0">
      <v-col cols="12" md="11">
        <v-card color="transparent" flat>
          <v-card color="transparent" flat>
            <v-btn href="/category/all/Motocross" class="btns_menu fontB mx-md-4" color="#fff" text>
              {{ getLang.HomePage.SubSliderCategories.komponenti }}
            </v-btn>
            <v-btn href="/category/all/Enduro" class="btns_menu fontB mx-md-4" color="#fff" text>
              {{ getLang.HomePage.SubSliderCategories.enduro }}
            </v-btn>
            <v-btn href="/category/all/Road_bikes" class="btns_menu fontB mx-md-4" color="#fff" text>
              {{ getLang.HomePage.SubSliderCategories.road }}
            </v-btn>
            <v-btn href="/category/all/Urban" class="btns_menu fontB mx-md-4" color="#fff" text>
              {{ getLang.HomePage.SubSliderCategories["e-road"] }}
            </v-btn>
            <v-btn href="/category/all/Bud_racing" class="btns_menu fontB mx-md-4" color="#fff" text>
              {{ getLang.HomePage.SubSliderCategories.accessories }}
            </v-btn>
          </v-card>
          <!-- <v-btn
            v-for="item in CategoriesJSON.filter((categ) =>
              Subcategories.includes(categ['Name code'])
            )"
            :key="item['Name code']"
            :href="'/category/' + $route.params.name + '/' + item['Name code']"
            class="btns_menu fontB mx-3 mx-md-4"
            color="#fff"
            text
          >
            {{ getLanguage == "Ru" ? item["Name Ru"] : item["Name Ro"] }}
          </v-btn> -->
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CategoriesJSON from "./../../assets/Categories.json";
import { mapGetters } from "vuex";

export default {
  name: "ParallaxCategory",
  data: () => ({
    CategoriesJSON: CategoriesJSON,
    Subcategories: [],
  }),
  mounted() {
    this.Subcategories = CategoriesJSON.filter((categ) =>
      categ["Name code"].toLowerCase().includes(
        this.$route.params.name[0].toLowerCase() +
        this.$route.params.name.slice(1)
      )
    )[0]["Subcategories (names code)"];
  },
  methods: {
    /* GoTo(categ) {
      this.$router.push({
        name: "categoryPage",
        params: {
          name: 'All',
          subname: categ
        },
      }).catch(() => { });
    } */
  },
  computed: {
    ...mapGetters(["getLanguage", "getLang"]),
    links() {
      let l = [
        {
          text: this.getLanguage == "Ru" ? "Домой" : "Acasă",
          disabled: false,
          href: "/",
        },
      ];

      let name = this.$route.params.name,
        subname = this.$route.params.subname;
      let categ = CategoriesJSON.filter((categ) =>
        categ["Name code"]
          .toLowerCase()
          .includes(this.$route.params.name.toLowerCase())
      )[0];

      if (name) {
        l.push({
          text: this.getLanguage == "Ru" ? categ["Name Ru"] : categ["Name Ro"],
          disabled: subname ? false : true,
          href: `/category/${name}`,
        });
      }
      if (subname) {
        let subcateg = CategoriesJSON.filter((categ) =>
          categ["Name code"]
            .toLowerCase()
            .includes(this.$route.params.subname.toLowerCase())
        )[0];
        l.push({
          text:
            this.getLanguage == "Ru"
              ? subcateg["Name Ru"]
              : subcateg["Name Ro"],
          disabled: true,
          href: `/category/${name}/${subname}`,
        });
      }

      return l;
    },
  },
};
</script>

<style scoped>
.h1_categorie_title {
  font-weight: 400;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.587);
}

.menu_sub {
  background: #040c1b;
}
</style>
<style>
.breadcrumbs_text .v-breadcrumbs__item {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.095em;
  color: #fff !important;
  transition: 0.2s;
}

.breadcrumbs_text .v-breadcrumbs__item:hover {
  text-decoration: underline;
  opacity: 0.9;
}

.breadcrumbs_text .v-breadcrumbs__item--disabled {
  opacity: 0.8;
}

.parallaxHome .v-parallax__content {
  padding: 0px;
}
</style>