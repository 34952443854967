<template>
  <v-footer dark padless>
    <v-card color="secondary" class="flex pt-13" flat tile>
      <v-row justify="center">
        <v-col cols="10" md="10">
          <v-row justify="center">
            <v-col cols="12" md="6">
              <v-img width="220px" src="../assets/logo.png" alt="logo PRO Enduro"></v-img>
              <v-card-title class="px-0 footer_text fontR mt-2 font_wrap">
                {{ getLang.Footer.textDescription }}
              </v-card-title>
              <v-card-actions class="mb-3">
                <v-btn href="contact@proenduro.md" target="_blank" icon aria-label="contact via email">
                  <svg width="21" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M13.6667 7.389L9.44444 11.6112L15.7778 17.9446L20 1.05566L1 8.44455L5.22222 10.5557L7.33333 16.889L10.5 12.6668"
                      stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </v-btn>
                <v-btn href="https://www.instagram.com/proenduro.md" target="_blank" icon
                  aria-label="contact via instagram">
                  <svg width="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="1" y="1" width="18" height="18" rx="4" stroke="white" stroke-width="2"
                      stroke-linecap="round" stroke-linejoin="round" />
                    <circle cx="10" cy="10" r="3.375" stroke="white" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round" />
                    <path d="M15.0625 4.93743V4.93855" stroke="white" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </v-btn>
                <!-- <v-btn href="#" target="_blank" icon aria-label="contact via facebook">
                  <svg
                    width="13"
                    viewBox="0 0 13 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 8V12H4V19H8V12H11L12 8H8V6C8 5.44772 8.44772 5 9 5H12V1H9C6.23858 1 4 3.23858 4 6V8H1"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </v-btn> -->
              </v-card-actions>
              <a href="mailto:contact@proenduro.md">contact@proenduro.md</a>
            </v-col>
            <v-col cols="12" offset-md="3" md="3">
              <v-card-title class="title_text fontB pt-0 font_wrap">
                {{ getLang.Footer.textUsefulLinks }}
              </v-card-title>
              <v-btn href="/terms/0" text class="useful_link fontB mt-2 font_wrap">
                {{ getLang.Footer.btnTerms }}
              </v-btn>
              <br />
              <v-btn href="/terms/1" text class="useful_link fontB mt-2 font_wrap">
                {{ getLang.Footer.btnPolitica }}
              </v-btn>
              <!-- <br />
              <v-btn href="/terms/5" text class="useful_link fontB mt-2 font_wrap">
                {{ getLang.Footer.btnСooperation }}
              </v-btn>
              <br />
              <v-btn href="/terms/4" text class="useful_link fontB mt-2 font_wrap">
                {{ getLang.Footer.btnPortfolio }}
              </v-btn>
              <br /> -->
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-card-text class="py-2 mt-6 gray--text text-center">
        © All rights reserved. Copyright {{ new Date().getFullYear() }}.
      </v-card-text>
      <v-row class="ma-0 povered_by" justify="center">
        <v-col cols="8" sm="4" md="3" lg="2" class="py-5">
          <a href="https://amark.pro/" target="_blank">
            <v-img class="img_povered_by" contain width="100%" src="../assets/footerAmark.png"></v-img>
          </a>
        </v-col>
        <!-- <v-card-title class="text_povered_by fontR font_wrap">
          <v-img contain height="80%" src="../assets/HomePage/amark.pro.png" class="mr-1"></v-img>
          <a class="link_povered_by mr-1" href="https://amark.pro/">amark.pro</a> development
        </v-card-title> -->
      </v-row>
    </v-card>
  </v-footer>
</template>

<script lang="ts">
import { mapGetters } from "vuex";

export default {
  name: "FooterPage",
  computed: {
    ...mapGetters(["getLang"]),
  },
};
</script>

<style scoped>
.img_povered_by {
  transition: .3s;
}

.img_povered_by:hover {
  cursor: pointer;
  filter: brightness(2);
}

.povered_by {
  background: #000;
}

.link_povered_by {
  color: #37E79D;
  text-decoration: none;
}

.text_povered_by {
  text-transform: none;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #E3E3E5;
}

.useful_link {
  text-transform: none;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}

.title_text {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #ffffff;
}

.footer_text {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #ffffff;
}
</style>