<template>
  <div class="productPage">
    <ParallaxProduct />
    <v-row id="productPageId" justify="center" class="fill-height" style="flex-flow: nowrap">
      <v-col cols="12" sm="10" align-self="start">
        <v-breadcrumbs class="breadcrumbs_product fontB" dark :items="links" divider=""></v-breadcrumbs>
      </v-col>
    </v-row>
    <Tovar :tovar="product" />
    <OtherProducts />
    <BestProduct />
    <Parallax />
    <ContactUs />
    <FooterPage />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CategoriesJSON from "./../assets/Categories.json";
import ParallaxProduct from "../components/Product/parallaxProduct.vue";
import Tovar from "../components/Product/Tovar.vue";
import OtherProducts from "../components/Product/OtherProducts.vue";
import BestProduct from "../components/Category/BestProduct.vue";
import Parallax from "../components/Home/Parallax.vue";
import ContactUs from "../components/Home/ContactUs.vue";
import FooterPage from "../components/FooterPage.vue";

export default {
  name: "ProductPage",
  components: {
    ParallaxProduct,
    Tovar,
    OtherProducts,
    BestProduct,
    Parallax,
    ContactUs,
    FooterPage,
  },
  data: () => ({
    links: [
      {
        text: "Домой",
        disabled: false,
        href: "/",
      },
    ],
    CategoriesJSON: CategoriesJSON,
    product: {}
  }),
  computed: {
    ...mapGetters(["getLang", "getLanguage", "getProducts"]),
  },
  mounted() {
    let name = this.$route.params.name,
      subname = this.$route.params.subname,
      id = this.$route.params.id;

    let categ = CategoriesJSON.filter((categ) =>
      categ["Name code"].toLowerCase().includes(
        this.$route.params.name[0].toLowerCase() +
        this.$route.params.name.slice(1)
      )
    )[0];

    if (name) {
      this.links.push({
        text: this.getLanguage == "Ru" ? categ["Name Ru"] : categ["Name Ro"],
        disabled: false,
        href: `/category/${name}`,
      });
    }
    if (subname) {
      let subcateg = CategoriesJSON.filter((categ) =>
        categ["Name code"].toLowerCase().includes(this.$route.params.subname.toLowerCase())
      )[0];
      //this.$route.params.subname[0].toUpperCase() +
      //this.$route.params.subname.slice(1)

      this.links.push({
        text: this.getLanguage == "Ru" ? subcateg["Name Ru"] : subcateg["Name Ro"],
        disabled: false,
        href: `/category/${name}/${subname}`,
      });
    }
    
    if (this.getProducts.length > 0) {
      this.product = this.getProducts.filter((tovar) => tovar["ID"] == id)[0];

      this.links.push({
        text: this.getLanguage == "Ru" ? this.product["Name Ru"] : this.product["Name Ro"],
        disabled: true,
        href: `#`,
      });
    }
  },
  beforeCreate() {
    let data = {
      changed_categories: [this.$route.params.name],
      changed_subcategories: this.$route.params.subname ? [this.$route.params.subname] : null
    };
    this.$store.dispatch("LoadProducts", data);
  },
};
</script>

<style scoped>
.productPage {
  background: #F1F1F1;
}
</style>
<style>
.breadcrumbs_product li:not(.v-breadcrumbs__divider) {
  transition: 0.2s;
  background: #d9d9d9;
  border-radius: 3px;
  transform: skewX(340deg);
  margin-top: 10px;
  margin-bottom: 10px;
}

.breadcrumbs_product .v-breadcrumbs__item {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.095em;
  color: #626772 !important;
  padding: 8px 22px;
  transform: skewX(20deg);
}

.breadcrumbs_product .v-breadcrumbs__divider {
  padding: 6px !important;
}

.breadcrumbs_product li:not(.v-breadcrumbs__divider):hover {
  background: #c2c1c1 !important;
  color: #343434 !important;
}

.breadcrumbs_product .v-breadcrumbs__item--disabled {
  opacity: 0.8;
}
</style>