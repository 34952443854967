import Vue from "vue";
import Vuex from "vuex";
//import { collection, getDocs } from "firebase/firestore";
import Ru from "../assets/language/Ru.json";
import Ro from "../assets/language/Ro.json";
import products from "../assets/products.json";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    language: localStorage.getItem("language"),
    products: [],
    lang: {},
    shopping_card: localStorage.getItem("shopping_card")
      ? JSON.parse(localStorage.getItem("shopping_card"))
      : {},
  },
  getters: {
    getLanguage: (state) => state.language,
    getProducts: (state) => state.products,
    getLang: (state) => state.lang,
    getCard: (state) => state.shopping_card,
  },
  mutations: {
    SetLanM(state, payload) {
      state.language = payload;
    },
    SetProductsM(state, payload) {
      state.products = payload;
    },
    SetlangM(state, payload) {
      state.lang = payload;
    },
    SetCardM(state, payload) {
      state.shopping_card = payload;
    },
  },
  actions: {
    SetLanuage({ commit, state }, payload) {
      if (payload) {
        localStorage.setItem("language", payload);
        commit("SetLanM", payload);
      }
      if (state.language == "Ru") {
        commit("SetlangM", Ru);
      } else {
        commit("SetlangM", Ro);
      }
    },
    SetCard({ commit }, payload) {
      if (payload) {
        let card = {};
        if (localStorage.getItem("shopping_card")) {
          card = JSON.parse(localStorage.getItem("shopping_card"));
          card[payload] = card[payload] ? card[payload] + 1 : 1;
          /* if (!card.includes(Number(payload))) {
            card.push(Number(payload));
          } */
        } else {
          card[payload] = 1;

          //card.push(Number(payload));
        }
        localStorage.setItem("shopping_card", JSON.stringify(card));
        commit("SetCardM", card);
      }
    },
    RemoveCard({ commit }, payload) {
      if (payload) {
        let card = {};
        if (localStorage.getItem("shopping_card")) {
          card = JSON.parse(localStorage.getItem("shopping_card"));
          card[payload] = card[payload] ? card[payload] - 1 : 0;
          localStorage.setItem("shopping_card", JSON.stringify(card));
          commit("SetCardM", card);
        }
      }
    },
    DeleteCard({ commit }, payload) {
      if (payload) {
        let card = [];
        if (localStorage.getItem("shopping_card")) {
          card = JSON.parse(localStorage.getItem("shopping_card"));
          delete card[payload];
          localStorage.setItem("shopping_card", JSON.stringify(card));
          commit("SetCardM", card);
        }
      }
    },
    async LoadProducts({ commit }, payload) {
      let data = [];

      let products_filter = products.filter((p) => {
        return payload.changed_categories.some((item) =>
          p.Categories.toLowerCase().includes(item.toLowerCase())
        );
      });

      if (
        payload.changed_categories[0] &&
        payload.changed_categories[0].toLowerCase() == "all" &&
        payload.changed_categories.length == 1
      ) {
        products_filter = products;
      }

      if (
        payload.changed_subcategories &&
        payload.changed_subcategories.length > 0
      ) {
        products_filter = products_filter.filter((p) => {
          return payload.changed_subcategories.some((item) =>
            p.Categories.toLowerCase().includes(item.toLowerCase())
          );
        });
      }
      products_filter.forEach((tovar) => {
        /* if (payload.subname) {
          if (typeof payload.subname == "string") {
            if (
              !tovar.Categories.toLowerCase().includes(
                payload.subname.toLowerCase()
              )
            )
              return;
          } else {
            let breakk = false;
            payload.subname.forEach((element) => {
              if (
                tovar.Categories.toLowerCase().includes(element.toLowerCase())
              ) {
                breakk = true;
                return;
              }
            });
            if (!breakk) {
              return;
            }
          }
        } else {
          console.log(tovar.Categories.toLowerCase().includes(payload.name.toLowerCase()))
          if (
            !tovar.Categories.toLowerCase().includes(payload.name.toLowerCase())
          )
            return;
        } */

        /* console.log(payload); */
        let nTovar = tovar;
        if (typeof tovar.Images == "string") {
          nTovar.Images = tovar.Images.split(",").map((i) => i.trimStart()); //.replace(/ /g, "")
        }
        //
        data.push(nTovar);
        commit("SetProductsM", data);
      });
    },
  },
  modules: {},
});
