<template>
  <v-container fluid class="mt-12">
    <v-row justify="center">
      <v-col cols="11" sm="10" md="9">
        <v-row justify="center">
          <v-col v-for="item in CategoriesJSON.filter((categ) =>
            Subcategories.includes(categ['Name code'])
          )" v-show="item['Image']" :key="item['Name code']" cols="6" sm="4" md="3">
            <v-card :href="'/category/' + $route.params.name + '/' + item['Name code']
              " flat color="transparent" class="cards">
              <v-img v-if="item['Image']" max-width="100%" aspect-ratio="1.5" contain
                :src="getIMGPathCategory(item['Image'])"></v-img>
              <v-card-title class="justify-center cards_title fontB font_wrap">
                {{ getLanguage == "Ru" ? item["Name Ru"] : item["Name Ro"] }}
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-card flat color="transparent">
            <v-card-title class="description_text fontR justify-center text-center font_wrap mt-3">
              {{ Description }}
            </v-card-title>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center" class="mt-8">
      <v-col cols="12" md="11">
        <v-divider></v-divider>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CategoriesJSON from "./../../assets/Categories.json";
import { mapGetters } from "vuex";

export default {
  name: "SubCategories",
  data: () => ({
    CategoriesJSON: CategoriesJSON,
    Subcategories: [],
    Description: "",
  }),
  mounted() {
    this.Subcategories = CategoriesJSON.filter((categ) =>
      categ["Name code"].toLowerCase().includes(
        this.$route.params.name[0].toLowerCase() +
        this.$route.params.name.slice(1)
      )
    )[0]["Subcategories (names code)"];
    this.Description = CategoriesJSON.filter((categ) =>
      categ["Name code"].toLowerCase().includes(
        this.$route.params.name[0].toLowerCase() +
        this.$route.params.name.slice(1)
      )
    )[0]["Description"];
  },
  computed: {
    ...mapGetters(["getLanguage", "getLang"]),
  },
  methods: {
    getIMGPathCategory(url) {
      if (url.includes('http')) return url
      return require(`@/assets/img/category/${url}`);
    },
  },
};
</script>

<style scoped>
.description_text {
  font-weight: 400;
  font-size: 20px;
  line-height: 141%;
  color: #313131;
}

.cards {
  transition: 0.2s;
}

.cards:hover {
  transform: scale(1.03);
  background-color: #faf9f9 !important;
}

.cards_title {
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  color: #040c1b;
}
</style>