import { render, staticRenderFns } from "./Waygom_tyres.vue?vue&type=template&id=28d0fd68&scoped=true&"
import script from "./Waygom_tyres.vue?vue&type=script&lang=js&"
export * from "./Waygom_tyres.vue?vue&type=script&lang=js&"
import style0 from "./Waygom_tyres.vue?vue&type=style&index=0&id=28d0fd68&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28d0fd68",
  null
  
)

export default component.exports