import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import About from "../views/AboutPage.vue";
import Terms from "../views/TermsPage.vue";
import CategoryPage from "../views/CategoryPage.vue";
import Product from "../views/Product.vue";
import Card from "../views/CardPage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "aboutPage",
    component: About,
  },
  {
    path: "/terms/:id?",
    name: "terms",
    component: Terms,
  },
  {
    path: "/category/:name/:subname?",
    name: "categoryPage",
    component: CategoryPage,
  },
  {
    path: "/product/:name/:subname?/:id",
    name: "ProductPage",
    component: Product,
  },
  {
    path: "/card",
    name: "CardPage",
    component: Card,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      if (savedPosition) {
        resolve({ top: savedPosition.top, behavior: "instant" });
      } else if (to.name == "ProductPage" && from.name != undefined) {
        setTimeout(() => {
          resolve({
            behavior: "instant",
            selector: "#productPageId",
            offset: { x: 0, y: 50 },
          });
        }, 1);
      } else {
        resolve({ top: 0, behavior: "instant" });
      }
    });
  },
});

export default router;
