<template>
  <v-container fluid class="px-0 py-6 mt-15 our_products">
    <v-row justify="center" class="text-left py-15">
      <v-col cols="10" class="">
        <v-row justify="center" class="mt-10 mb-4">
          <v-col cols="12" md="6" align-self="center">
            <v-card-title class="title_products fontB">
              {{ getLang.AboutPage.our_prod_title }}
            </v-card-title>
            <v-card-subtitle class="subtitle_products mt-1 font_wrap">
              <span v-html="getLang.AboutPage.our_prod_text"></span>
            </v-card-subtitle>
          </v-col>
          <v-col
            cols="12"
            offset-md="1"
            md="5"
            align-self="center"
            style="position: relative"
          >
            <v-img
              class="img_absolute"
              max-width="100%"
              aspect-ratio="1"
              max-height="100%"
              src="../../assets/About/2.png"
            >
            </v-img>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
      
  <script>
import { mapGetters } from "vuex";

export default {
  name: "ourProducts",
  computed: {
    ...mapGetters(["getLang"]),
  },
};
</script>
<style scoped>
.subtitle_products {
  font-weight: 500;
  font-size: 18px;
  line-height: 135.84%;
  letter-spacing: -0.03em;
  color: #eaeaea;
}
.title_products {
  font-weight: 600;
  font-size: 42px;
  line-height: 50px;
  letter-spacing: -0.05em;
  color: #dcbb10;
  white-space: normal;
  text-wrap: balance;
  word-break: break-word;
}
.our_products {
  background-color: #040c1b;
}
@media only screen and (max-width: 864px) {
  .img_absolute {
    right: 0%;
  }
}
@media only screen and (min-width: 864px) {
  .img_absolute {
    right: -15%;
    transform: scale(1.1);
  }
}
</style>